import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllMessagesByChatId = createAsyncThunk(
   "messages/getAllMessagesByChatId",
   async ({ chatId, page }) => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API}/messages/${chatId}?page=${page}`
         );

         return response.data;
      } catch (err) {
         console.log(err);
      }
   }
);
export const loadMoreChatByChatId = createAsyncThunk(
   "messages/loadMoreChatByChatId",
   async ({ chatId, page }) => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API}/messages/${chatId}?page=${page}`
         );
         return response.data;
      } catch (err) {
         console.log(err);
      }
   }
);

const initialState = {
   selectedChat: {},
   messages: [], // State for storing inital loaded messages
   newMessages: [], // State for storing newly received messages
   loadedMessage: [], // State for storeing message if load more clicked
   nonReadMessageCount: 0,
   page: 1,
   loading: false,
   error: "",
   clicked: false,
   totalMessage: 0,
};

const messageSlice = createSlice({
   name: "messages",
   initialState,
   reducers: {
      saveSelectedChat(state, action) {
         const obj = action.payload;
         if (Object.keys(obj).length !== 0) {
            state.selectedChat = action.payload;
         }
      },
      updateSentMessage(state, action) {
         const newMessage = action.payload;
         // state.messages.unshift(newMessage)
         if (state.newMessages.length > 0) {
            state.newMessages = [...state.newMessages, newMessage];
         } else {
            state.messages = [...state.messages, newMessage];
         }
      },
      updateNewMessage(state, action) {
         const { newMessage } = action.payload || {};
         if (Object.keys(newMessage).length > 0) {
            state.newMessages = [...state.newMessages, newMessage];
         } else {
            // Handle the case of empty newMessage (no new messages)
         }
      },
      //Reducer for updating the message status of specific message if the status changed
      updateMessageStatus(state, action) {
         const messageId = action.payload?.message.id;
         const status = action.payload.status;
         const message = state.messages;
         const newMsg = state.newMessages;
         const loadMsg = state.loadedMessage;

         if (message.length > 0) {
            state.messages = message.map((msg) => {
               const msgId = msg.message?.id;
               if (msgId === messageId) {
                  msg.status = status;
               }
               return msg;
            });
         }
         if (newMsg.length > 0) {
            state.newMessages = newMsg.map((msg) => {
               const msgId = msg.message?.id;

               if (msgId === messageId) {
                  msg.status = status;
               }
               return msg;
            });
         }
         if (loadMsg.length > 0) {
            state.loadedMessage = loadMsg.map((msg) => {
               const msgId = msg.message?.id;
               if (msgId === messageId) {
                  msg.status = status;
               }
               return msg;
            });
         }
      },
      updatePageNumber(state) {
         state.page = state + 1;
      },
      setClicked(state, action) {
         state.clicked = action.payload;
      },
      setNewMessageEmpty(state, action) {
         state.newMessages = [];
      },
      setMessageEmpty(state) {
         state.messages = [];
      },
      setLoadedMessageEmpty(state) {
         state.loadedMessage = [];
      },
      resetMessageState(state) {
         state.loadedMessage = [];
         state.newMessages = [];
         state.messages = [];
         state.messageCount = 0;
         state.nonReadMessageCount = 0;
         state.page = 1;
      },
      updateReceivedMsgTime(state, action) {
         // Ensure a copy to avoid mutation
      const chat=state.selectedChat

         // Update or create the field using spread operator with optional chaining
         state.selectedChat = {
            ...chat,
            last_msg_received_at: action.payload,
         };

         
      },
   },

   extraReducers: (builder) => {
      builder
         .addCase(getAllMessagesByChatId.pending, (state) => {
            state.loading = true;
         })
         .addCase(getAllMessagesByChatId.fulfilled, (state, action) => {
            state.loading = false;
            state.messages = action.payload.messages;
            state.nonReadMessageCount = action.payload.messageCount;
            state.totalMessage = action.payload.totalMessage;
            // state.clicked = false;
         })
         .addCase(getAllMessagesByChatId.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
         })
         .addCase(loadMoreChatByChatId.pending, (state) => {
            state.loading = true;
         })
         .addCase(loadMoreChatByChatId.fulfilled, (state, action) => {
            state.loading = false;
            const { messages, page, messageCount, results, totalMessage } =
               action.payload;
            // Append new messages to existing ones for pagination
            state.loadedMessage = [
               ...action.payload.messages,
               ...state.loadedMessage,
            ];
            // state.messages = [...action.payload.messages, ...state.messages];
            state.page = Number(page);
         })
         .addCase(loadMoreChatByChatId.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
         });
   },
});

export default messageSlice.reducer;

export const {
   saveSelectedChat,
   updateNewMessage,
   updatePageNumber,
   setClicked,
   setNewMessageEmpty,
   updateSentMessage,
   setMessageEmpty,
   resetMessageState,
   updateMessageStatus,
   updateReceivedMsgTime,
} = messageSlice.actions;
