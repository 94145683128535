import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./slices/userSlice";
import sideBarReducer from "./slices/sideBarSlice";
import chatReducer from "./slices/chatSlice";
import messgeReducer from "./slices/messageSlice";
import notificationReducer from "./slices/notificationSlice";
import bulkSendReportReducer from "./slices/bulkSendReportSlice";
import contactReducer from "./slices/contactSlice";
import templateReducer from "./slices/templateSlice";
import insightsReducer from "./slices/insightsSlice";

const appStore = configureStore({
   reducer: {
      user: userReducer,
      sidebar: sideBarReducer,
      chat: chatReducer,
      messages: messgeReducer,
      notification: notificationReducer,
      bulkSend: bulkSendReportReducer,
      contacts: contactReducer,
      template: templateReducer,
      insights:insightsReducer
   },
});
export default appStore;
