import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React from "react";
import moment from "moment";

export const sendMessageStatus = (status) => {
   if (status === 1) {
      return (
         <DoneIcon
            sx={{ fontSize: "16px", color: "#978f8f", marginLeft: "2px" }}
         />
      );
   } else if (status === 2) {
      return (
         <DoneAllIcon
            sx={{ fontSize: "16px", color: "#978f8f", marginLeft: "2px" }}
         />
      );
   } else if (status === 3) {
      return (
         <DoneAllIcon
            sx={{ fontSize: "16px", color: "blue", marginLeft: "2px" }}
         />
      );
   } else if (status === 4) {
      return (
         <ErrorOutlineIcon
            sx={{ fontSize: "16px", color: "red", marginLeft: "2px" }}
         />
      );
   }
};

export const fileTypes = [
   "text/plain",
   "application/vnd.ms-excel",
   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
   "application/msword",
   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
   "application/vnd.ms-powerpoint",
   "application/vnd.openxmlformats-officedocument.presentationml.presentation",
   "application/pdf",
   "image/jpeg",
   "image/png",
   "image/webp",
   "video/3gp",
   "video/mp4",
];

export const parseText = (text) => {
   // Step 1: Replace \\n with actual line breaks
   let formattedText = text.replace(/\\n/g, "\n");

   // Step 2: Split the text by newline characters to handle each line separately
   const lines = formattedText.split("\n");

   // Step 3: Process each line to replace *text* with <strong>text</strong>
   return lines.map((line, index) => {
      const parts = line.split(/(\*[^*]+\*)/); // Split by *...* to identify bold parts

      return (
         <React.Fragment key={index}>
            {parts.map((part, index) =>
               part.startsWith("*") && part.endsWith("*") ? (
                  <strong key={index}>{part.slice(1, -1)}</strong> // Remove * and wrap in <strong>
               ) : (
                  part
               )
            )}
            <br /> {/* Add line break after each line */}
         </React.Fragment>
      );
   });
};

export const getMessageType = (files) => {
   if (files.type === "application/pdf") {
      return "document";
   } else if (
      files.type === "image/jpeg" ||
      files.type === "image/webp" ||
      files.type === "image/png"
   ) {
      return "image";
   } else if (files.type === "video/3gp" || files.type === "video/mp4") {
      return "video";
   } else if (
      files.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
   ) {
      return "document";
   }
};

/**
 * Function to check the current chat is expired or not
 *
 */

export const checkChatExpired = (selectedChat) => {
   /**
    * The below code is for check the expiration time with last_mes_received_at field
    */

   const expirationTimestamp = selectedChat?.last_msg_received_at;
   if (!expirationTimestamp) {
      return true;
   }

   const date = new Date(expirationTimestamp * 1000); // Convert timestamp to milliseconds

   date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // Add 24 hours in milliseconds
   const newTimestamp = Math.floor(date.getTime() / 1000);
   // Create a moment object from the timestamp

   const expirationDate = moment.unix(newTimestamp);

   /**
    * This is for checking the expiration time with expire_at filed
    */
   // const expirationTimestamp = selectedChat?.expire_at;
   // if (!expirationTimestamp) {
   //    return true;
   // }
   // const expirationDate = moment.unix(expirationTimestamp);

   const currentDate = moment();

   if (expirationDate.isBefore(currentDate)) {
      console.log("The expiration date is in the past.");
      return true;
   } else if (expirationDate.isSame(currentDate)) {
      console.log("The expiration date is the same as the current date.");
      return true;
   } else {
      console.log("The expiration date is in the future.");
      return false;
   }
};

// Function to check if the selected file type is valid for documents
function isValidDocumentType(fileType) {
   const validDocumentTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/pdf",
   ];

   return validDocumentTypes.includes(fileType);
}

export const validateTemplateFile = (file, headerType) => {
   // Validation based on headerType
   if (headerType === "IMAGE" && !file.type.startsWith("image/")) {
      return { error: "Please select an image file." };
      // You may also clear the input field if needed
      // event.target.value = "";
   } else if (headerType === "VIDEO" && !file.type.startsWith("video/")) {
      return { error: "Please select a video file." };
      // event.target.value = "";
   } else if (headerType === "DOCUMENT" && !isValidDocumentType(file.type)) {
      return { error: "Please select a valid document file." };
      // event.target.value = "";
   } else {
      return true;
   }
};

// Download sample csv file
export const handleDownload = () => {
   // const fileUrl = `${process.env.PUBLIC_URL}/example.xlsx`;
   const fileUrl = `${process.env.REACT_APP_PUBLIC_URL}/Sample.xlsx`;
   const link = document.createElement("a");
   link.href = fileUrl;
   link.download = "example.xlsx";
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
};
// Download sample bulk contact upload csv file
export const handleDownloadBulkContact = () => {
   // const fileUrl = `${process.env.PUBLIC_URL}/example.xlsx`;
   const fileUrl = `${process.env.REACT_APP_PUBLIC_URL}/Bulk_contact_sample.xlsx`;
   const link = document.createElement("a");
   link.href = fileUrl;
   link.download = "example.xlsx";
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
};
