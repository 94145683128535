import React from "react";
import { Avatar, Box, Typography, styled } from "@mui/material";
import moment from "moment";
import DoneIcon from "@mui/icons-material/Done";
import { sendMessageStatus } from "../../../constant/helperFunctions";

const Message = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginBottom: "5px",
});

function TextMessage({ content }) {
  const time = moment(content.createdAt).format("h:mm a");
  const statusIcon = sendMessageStatus(content?.status);


  return (
    <Box
      key={content._id}
      sx={{
        height: "auto",
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
        maxWidth: content.flag === "incoming" ? "450px" : null,
        justifyContent: content.flag === "outgoing" ? "end" : "start",
      }}
    >
      {content.flag === "incoming" && (
        <Box p={1}>
          <Avatar
            sx={{
              width: "30px",
              height: "30px",
              backgroundColor: "black",
              color: "#fff",
            }}
          />
        </Box>
      )}
      <Message>
        <Box
          p={1}
          sx={{
            width: "auto",
            height: "auto",
            backgroundColor: content.flag === "incoming" ? "#F4F4F6" : "#d9fdd3",
            borderRadius: content.flag === "incoming" ? "0px 15px 15px 15px" : "15px 15px 0px 15px",
          }}
        >
          
          <Typography sx={{ fontSize: "13px" }}>{content.message?.text}</Typography>
          <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "11px",
                fontWeight: "400",
                color: "#978f8f",
                textAlign: content.flag === "incoming" ? "start" : "end",
              }}
            >
              {time}
            </Typography>
            {content.flag === "outgoing" && statusIcon}
          </Box>
        </Box>
      </Message>
      {content.flag === "outgoing" && (
        <Box p={1}>
          <Avatar
            sx={{
              width: "30px",
              height: "30px",
              backgroundColor: "black",
              color: "#fff",
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default TextMessage;
