import {
   Avatar,
   AvatarGroup,
   Box,
   Button,
   Popover,
   Typography,
   styled,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import SortIcon from "../../assets/icons/svg/read.svg";
import ChatList from "./ChatList";
import Profile from "../profile/Profile";
import BusinessProfile from "../profile/BusinessProfile";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import TemplatePreviewModal from "../templates/TemplatePreviewModal";
import BulkSendModal from "./components/BulkSendModal";
import NewChatModal from "./components/NewChatModal";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import { addNewAddedChat, setSearchedChat } from "../../store/slices/chatSlice";
import {
   getAllMessagesByChatId,
   saveSelectedChat,
} from "../../store/slices/messageSlice";
import { validateQatarMobileNumber } from "../../utils/helperFuncion";
// import { setNewChat } from "../../store/slices/chatSlice";

const UserBox = styled(Box)({
   // position: "absolute",
   borderRadius: "15px",
   top: 0,
   height: "100%",
   backgroundColor: "#212529",
   width: "100%",
});

const StyledTextField = styled(TextField)(({ theme }) => ({
   color: "#fff",
   backgroundColor: "#fff",
   borderRadius: "15px",
   "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
      {
         "-webkit-appearance": "none",
         margin: 0,
      },
   "& input[type=number]": {
      "-moz-appearance": "textfield",
   },
}));

function UserSection({ chats, profileData }) {
   const [searchTerm, setSearchTerm] = useState("");
   const [openProfile, setOpenProfile] = useState(false);
   const [openBusinessProfile, setOpenBusinessProfile] = useState(false);
   const [file, setFile] = useState(null);
   const [anchorEl, setAnchorEl] = useState(null);
   const [openBulkSendTemplate, setOpenBulkSendTemplate] = useState(false);
   const [templates, setTemplates] = useState([]);
   const [openNewChat, setOpenNewChat] = useState(false);
   const [newContact, setNewContact] = useState("");
   const [isValid, setIsValid] = useState(true);
   const searchResult = useSelector((state) => state.chat.searchResult);

   const dispatch = useDispatch();

   const navigate = useNavigate();

   const { newAddedChat } = useSelector((state) => state.chat);

   //Function to close bulk send modal
   const handleCloseBulkSendModal = () => setOpenBulkSendTemplate(false);

   //Function to add new chat to state
   const addNewChat = () => {
      if (!newContact) {
         toast.error(" Please enter valid mobile number.");
         return;
      }

      let existChat = {};
      let contact = newContact;
      // if (newContact&&!newContact.startsWith("974")) {
      //    contact = "974" + newContact;
      // }
      chats.forEach((chatItem) => {
         if (chatItem.chat.phone_number === contact) {
            existChat = chatItem.chat;
         }
      });
      if (Object.keys(existChat).length > 0) {
         dispatch(saveSelectedChat(existChat));
         dispatch(getAllMessagesByChatId({ chatId: existChat._id, page: 1 }));
      } else {
         const chatData = {
            chat: {
               _id: "new",
               phone_number: contact,
            },
            messageCount: 0,
         };
         dispatch(addNewAddedChat(chatData));
      }
      setOpenNewChat(false);
      setNewContact("");
   };

   //Function to change the contact state
   const handleContactChange = (e) => {
      const value = e.target.value;
      // console.log(value);
      setNewContact(value);

      // // Validate the number on every change
      // const isValid = value.length > 11 ? false : true;
      // console.log(isValid);
      // setIsValid(isValid);
   };

   const handleClick = (event) => setAnchorEl(event.currentTarget);
   // Function to handle file select
   const handleFileSelect = (e) => setFile(e.target.files[0]);

   // Function to handle file upoload
   const handleFileUpload = async () => {
      const formData = new FormData();
      formData.append("contacts", file, file.name);
      const response = await axios.post(
         `${process.env.REACT_APP_API}/contacts`,
         formData
      );

      if (response.data.status === "success") toast.success("File Uploaded");
      else toast.error("Somthing went wrong");
   };

   const open = Boolean(anchorEl);
   const id = open ? "simple-popover" : undefined;
   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleSearch = async (e) => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API}/contacts/search?text=${searchTerm}`
         );
         if (response && response?.data?.status === "success") {
            console.log(response.data);
         }
      } catch (err) {
         console.log(err);
      }

      // Call the onSearch callback with the current search term
   };

   const handleInputChange = (e) => {
      setSearchTerm(e.target.value);
      const searchkey = e.target.value;
      dispatch(setSearchedChat(searchkey));
   };

   const handleSetOpenNewChat = () => {
      setOpenNewChat(true);
      setAnchorEl(null);
   };

   // const handleKeyPress = (event) => {
   //   // If the Enter key is pressed, perform the search
   //   if (event.key === "Enter") {
   //     handleSearch();
   //   }
   // };

   return (
      <>
         <Box
            sx={{
               position: "relative",
               height: "100%",
               overflow: "hidden",
            }}
         >
            <Toaster />
            <UserBox>
               <Box sx={{ height: "126px", padding: "15px" }}>
                  <Box
                     sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                     <Box display="flex" alignItems="center" width="100px">
                        <AvatarGroup>
                           {/* <Avatar
                    onClick={() => setOpenProfile(true)}
                    sx={{
                      color: "black",
                      background: "#fff",
                      width: "45px",
                      height: "45px",
                    }}
                  /> */}
                           <Avatar
                              onClick={() => setOpenBusinessProfile(true)}
                              sx={{
                                 color: "black",
                                 background: "#fff",
                                 width: "45px",
                                 height: "45px",
                                 "& .MuiAvatarGroup-root": {
                                    marginLeft: "-15px",
                                 },
                              }}
                           >
                              <BusinessCenterIcon />
                           </Avatar>
                        </AvatarGroup>
                        <Typography color="#fff" ml={1} fontSize="16px">
                           User
                        </Typography>
                     </Box>
                     <Box
                        gap={2}
                        sx={{
                           display: "flex",
                           justifyContent: "end",
                           width: "100px",
                        }}
                     >
                        <AddIcon
                           aria-describedby={id}
                           onClick={handleClick}
                           sx={{ color: "#fff", fontSize: "30px" }}
                        />
                        <Popover
                           id={id}
                           open={open}
                           anchorEl={anchorEl}
                           onClose={handleClose}
                           anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                           }}
                        >
                           <Box display="flex" flexDirection="column">
                              <Button
                                 sx={{
                                    color: "black",
                                    textTransform: "capitalize",
                                 }}
                                 onClick={() => handleSetOpenNewChat(true)}
                              >
                                 New Chat
                              </Button>
                              <Button
                                 sx={{
                                    color: "black",
                                    textTransform: "capitalize",
                                 }}
                                 onClick={() => setOpenBulkSendTemplate(true)}
                              >
                                 Bulk send template
                              </Button>
                              <Button
                                 sx={{
                                    color: "black",
                                    textTransform: "capitalize",
                                 }}
                                 onClick={() => navigate("/contacts")}
                              >
                                 Add Contacts
                              </Button>
                           </Box>
                        </Popover>
                        {/* <TuneIcon sx={{ color: "#fff", fontSize: "30px" }} /> */}
                        {/* <MoreVertIcon sx={{ color: "#fff", fontSize: "30px" }} /> */}
                     </Box>
                  </Box>
                  {openNewChat ? (
                     <Box display="flex" alignItems="center" mt={3}>
                        <Box
                           height="100%"
                           width="100%"
                           borderRadius="10px"
                           bgcolor="#fff"
                        >
                           <StyledTextField
                              sx={{}}
                              placeholder="Enter contact number"
                              variant="outlined"
                              size="small"
                              type="number"
                              fullWidth
                              value={newContact}
                              onChange={handleContactChange}
                           />
                           <div>
                              {/* <PhoneInput
                              defaultCountry="IN"
                              value={newContact}
                              onChange={handleContactChange}
                              placeholder="Phone number"
                              style={{
                                 height: "40px",
                                 padding: "0px 6px 0px",
                                 borderRadius: "10px",
                                 backgroundColor: "white",
                                 outline: "none",
                                 border: "1px solid #000",
                                 minWidth: "100%",
                                 ":focus": {
                                    color: "red",
                                 },
                              }} */}
                              {/* /> */}
                           </div>
                        </Box>
                        <Box
                           sx={{ ml: "5px", height: "100%", display: "flex" }}
                           gap={1}
                        >
                           <Button
                              onClick={() => addNewChat()}
                              sx={{
                                 backgroundColor: "black",
                                 color: "#fff",
                                 ":hover": {
                                    backgroundColor: "black",
                                 },
                              }}
                              variant="contained"
                           >
                              Send
                           </Button>
                           <Button
                              onClick={() => setOpenNewChat(false)}
                              sx={{
                                 backgroundColor: "black",
                                 color: "#fff",
                                 ":hover": {
                                    backgroundColor: "black",
                                 },
                              }}
                              variant="contained"
                           >
                              <CloseIcon />
                           </Button>
                        </Box>
                     </Box>
                  ) : (
                     <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mt={3}
                        mr={2}
                     >
                        <TextField
                           sx={{
                              color: "#fff",
                              backgroundColor: "#fff",
                              borderRadius: "5px",
                           }}
                           placeholder="Search..."
                           variant="outlined"
                           size="small"
                           fullWidth
                           value={searchTerm}
                           onChange={handleInputChange}
                           // onKeyPress={handleKeyPress}
                           InputProps={{
                              endAdornment: (
                                 <InputAdornment position="end">
                                    <IconButton onClick={handleSearch}>
                                       <SearchIcon />
                                    </IconButton>
                                 </InputAdornment>
                              ),
                           }}
                        />
                        {/* <Box><img src={SortIcon} alt="sort" /></Box> */}
                     </Box>
                  )}
               </Box>
               <Box
                  sx={{
                     width: "100%",
                     backgroundColor: "white",
                     height: "100%",
                     borderRadius: "15px",
                     // padding: "10px 5px 10px 5px",
                     overflowY: "scroll",
                     paddingTop: "15px",
                  }}
               >
                  {Object.keys(newAddedChat).length > 0 && (
                     <ChatList chatData={newAddedChat} newChat={true} />
                  )}
                  {searchResult?.map((chatData) => (
                     <ChatList key={chatData?.chat?._id} chatData={chatData} />
                  ))}
               </Box>
            </UserBox>
            <Profile active={openProfile} setOpenProfile={setOpenProfile} />
            <BusinessProfile
               profileData={profileData}
               active={openBusinessProfile}
               setOpenProfile={setOpenBusinessProfile}
            />
         </Box>
         {/* <TemplatePreviewModal open={openBulkSendTemplate} templates={templates}/> */}
         <BulkSendModal
            open={openBulkSendTemplate}
            handleClose={handleCloseBulkSendModal}
         />
      </>
   );
}

export default UserSection;
