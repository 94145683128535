import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
   Avatar,
   Box,
   Checkbox,
   Container,
   CssBaseline,
   FormControl,
   FormControlLabel,
   Grid,
   IconButton,
   InputAdornment,
   InputLabel,
   Link,
   OutlinedInput,
   Typography,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login = ({setIsForgotPassword}) => {
   const navigate = useNavigate();

   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [emailError, setEmailError] = useState("");
   const [passwordError, setPasswordError] = useState("");
   const [submitError, setSubmitError] = useState("");
   const [showPassword, setShowPassword] = useState(false);


   const validateEmail = (email) => {
      const re =
         // eslint-disable-next-line no-useless-escape
         /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return re.test(String(email).toLowerCase());
   };

   const handleSubmit = async (event) => {
      event.preventDefault();

      if (!email || !validateEmail(email)) {
         setEmailError("Please enter a valid email");
         return;
      } else {
         setEmailError("");
      }

      if (!password) {
         setPasswordError("Password is required");
         return;
      } else {
         setPasswordError("");
      }

      try {
         const response = await axios.post(
            `${process.env.REACT_APP_API}/user/signIn`,
            {
               email,
               password,
            }
         );

         if (!response.data.status) {
            throw new Error(response.data.error || "Failed to sign in");
         }

         // Reset form fields on successful login
         setEmail("");
         setPassword("");
         localStorage.setItem("user", response.data.userId);
         localStorage.setItem("login", true);
         navigate("/dashboard");
      } catch (error) {
         console.log(error);
         setSubmitError(error.response.data.message);
      }
   };

   const handleClickShowPassword = () => setShowPassword((show) => !show);

   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };
   return (
      <Container component="main" maxWidth="xs">
         <CssBaseline />
         <Box
            sx={{
               marginTop: 8,
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
            }}
         >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
               {/* <LockOutlinedIcon /> */}
            </Avatar>
            <Typography component="h1" variant="h5">
               Sign in
            </Typography>
            <Box
               component="form"
               onSubmit={handleSubmit}
               noValidate
               sx={{ mt: 1 }}
            >
               <TextField
                  error={!!emailError}
                  helperText={emailError}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
               />
               {/* <TextField
            error={!!passwordError}
            helperText={passwordError}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          /> */}
               <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                     Password
                  </InputLabel>
                  <OutlinedInput
                     id="outlined-adornment-password"
                     type={showPassword ? "text" : "password"}
                     onChange={(e) => setPassword(e.target.value)}
                     endAdornment={
                        <InputAdornment position="end">
                           <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                           >
                              {showPassword ? (
                                 <VisibilityOff />
                              ) : (
                                 <Visibility />
                              )}
                           </IconButton>
                        </InputAdornment>
                     }
                     label="Password"
                  />
               </FormControl>
               {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
               {submitError && (
                  <Typography color="error" variant="body2" align="center">
                     {submitError}
                  </Typography>
               )}
               <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
               >
                  Sign In
               </Button>
               <Grid container>
                  <Grid item xs>
                     <Link href="/forgot-password" variant="body2" >
                        Forgot password?
                     </Link>
                  </Grid>
                  <Grid item>
                     {/* <Link href="#" variant="body2">
                        {"Don't have an account? Sign Up"}
                     </Link> */}
                  </Grid>
               </Grid>
            </Box>
         </Box>
      </Container>
   );
};

export default Login;
