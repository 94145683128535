import {
   Box,
   Button,
   CircularProgress,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
   styled,
} from "@mui/material";
import axios from "axios";
import React, { useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { handleDownloadBulkContact } from "../../constant/helperFunctions";

const rows = [
   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
   createData("Eclair", 262, 16.0, 24, 6.0),
   createData("Cupcake", 305, 3.7, 67, 4.3),
   createData("Gingerbread", 356, 16.0, 49, 3.9),
];
function createData(name, calories, fat, carbs, protein) {
   return { name, calories, fat, carbs, protein };
}
const VisuallyHiddenInput = styled("input")({
   clip: "rect(0 0 0 0)",
   clipPath: "inset(50%)",
   height: 1,
   overflow: "hidden",
   position: "absolute",
   bottom: 0,
   left: 0,
   whiteSpace: "nowrap",
   width: 1,
});

function ImportContacts() {
   const fileInputRef = useRef(null);
   const [loading, setLoading] = useState(false);
   const [savedContacts, setSavedContacts] = useState([]);
   const [csvfile, setcsvFile] = useState(null);
   console.log({ csvfile });

   // Trigger the file input to open the file select dialog
   const handleClick = () => {
      fileInputRef.current.click();
   };

   // Function to handle file upoload
   const handleFileUpload = async () => {
      if (!csvfile) return;
      const formData = new FormData();
      formData.append("contacts", csvfile, csvfile.name);
      setLoading(true);
      try {
         const response = await axios.post(
            `${process.env.REACT_APP_API}/contacts`,
            formData
         );
         setLoading(false);
         if (response.data.status === "success") {
            setSavedContacts(response.data.results);
            setcsvFile(null);
            toast.success("File Uploaded");
         } else toast.error("Somthing went wrong");
      } catch (err) {
         setLoading(false);
         console.log(err);
         toast.error("Somthing went wrong");
      }
   };

   const handleRemoveFIle = () => setcsvFile(null);

   const handleFileChange = (event) => {
      // Handle selected file(s) here
      const file = event.target.files[0];
      setcsvFile(file);
      // handleFileUpload(file);
   };

   const downloadSampleCsv = () => {
      const fileUrl = "../../assets/sample/contacts_sample.csv";
      fetch(fileUrl)
         .then((response) => response.blob())
         .then((csvData) => {
            const blob = new Blob([csvData], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);

            // Create a temporary anchor element
            const a = document.createElement("a");
            a.href = url;
            // Specify the filename with .csv extension
            const fileName = "contacts_sample.csv";

            // Set the download attribute with the new filename
            a.setAttribute("download", fileName);

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            // const anchor = document.createElement('a');
            // anchor.href = fileUrl;
            // anchor.download = 'contact_sample.csv';
            // document.body.appendChild(anchor);
            // anchor.click();
            // document.body.removeChild(anchor);
         })
         .catch((err) => console.log("error"));
   };
   return (
      <Box
         mt={8}
         ml={5}
         mr={4}
         sx={{
            width: "100%",
            height: "auto",
            borderTop: "1px solid grey",
         }}
      >
         <Toaster />
         <Typography fontSize="20px" fontWeight="600" mt={2}>
            Import Contacts
         </Typography>
         <Box
            mt={2}
            sx={{
               borderRadius: "15px",
               backgroundColor: "#fff",
               padding: "15px",
               display: "flex",
               maxHeight: "430px",
               height: "430px",
               overflowY: "scroll",
            }}
         >
            <Box pr={2}>
               <Typography fontWeight="600">Prepare your file</Typography>
               <Typography
                  sx={{ mt: "10px", lineHeight: "32px", fontSize: "14px" }}
               >
                  To add your contacts, download the sample file and then upload
                  your completed file here.
               </Typography>

               <Box mt={2}>
                  {csvfile ? (
                     <Box display="flex" alignItems="center" gap={2}>
                        <Box>{csvfile?.name}</Box>

                        <Button
                           startIcon={<CloudUploadIcon />}
                           onClick={handleFileUpload}
                           sx={{
                              backgroundColor: "#0F61A5",
                              borderRadius: "10px",
                           }}
                           variant="contained"
                        >
                           Upload
                        </Button>
                        <Button
                           startIcon={<DeleteIcon />}
                           onClick={handleRemoveFIle}
                           sx={{
                              // backgroundColor: "#0F61A5",
                              borderRadius: "10px",
                           }}
                           variant="outlined"
                        >
                           Delete
                        </Button>
                     </Box>
                  ) : (
                     <Button
                        sx={{
                           backgroundColor: "#0F61A5",
                           borderRadius: "10px",
                        }}
                        component="label"
                        role={undefined}
                        variant="contained"
                        disabled={loading ? true : false}
                        tabIndex={-1}
                        // startIcon={<CloudUploadIcon />}
                     >
                        {loading ? (
                           <>
                              {"Uploading.. "}
                              <CircularProgress
                                 style={{
                                    height: "24px",
                                    width: "24px",
                                    color: "grey",
                                 }}
                              />{" "}
                           </>
                        ) : (
                           "browse .CSV FILE"
                        )}

                        <VisuallyHiddenInput
                           type="file"
                           accept={".csv"}
                           onChange={handleFileChange}
                        />
                     </Button>
                  )}
               </Box>
               <Box mt={2}>
                  <Button
                     sx={{ backgroundColor: "#0F61A5", borderRadius: "10px" }}
                     variant="contained"
                     onClick={handleDownloadBulkContact}
                  >
                     <DownloadIcon />
                     DOWNLOAD .CSV SAMPLE
                  </Button>
               </Box>
            </Box>
            {savedContacts.length > 0 && (
               <Box>
                  <TableContainer component={Paper}>
                     <Table
                        sx={{
                           width: 450,
                           minWidth: 250,
                        }}
                        aria-label="simple table"
                     >
                        <TableHead
                           sx={{
                              background:
                                 "linear-gradient(90deg, #FF97B5 -4.85%, #6E2A85 87.03%)",
                           }}
                        >
                           <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell align="right">Contacts</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {savedContacts?.map((row) => (
                              <TableRow
                                 key={row.name}
                                 sx={{
                                    "&:last-child td, &:last-child th": {
                                       border: 0,
                                    },
                                 }}
                              >
                                 <TableCell component="th" scope="row">
                                    {row.name}
                                 </TableCell>
                                 <TableCell align="right">
                                    {row.phone_number}
                                 </TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </Box>
            )}
         </Box>
      </Box>
   );
}

export default ImportContacts;
