import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TemplateViewModal from "./TemplateViewModal";
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';

function ListTemplate({
   openTemplateList,
   setOpenTemplateList,
   setMessageList,
   selectedChat,
}) {
   const [openTemplate, setOpenTemplate] = useState(false);
   const [templates, setTemplates] = useState([]);
   const [selectedTemplate, setSelectedTemplate] = useState();

   const handleOpenTemplateModal = (item) => {
      setSelectedTemplate(item);
      setOpenTemplate(true);
   };
   const handleCloseTemplateModal = () => setOpenTemplate(false);
   const getAllTemplates = async () => {
      console.log("template calling")
      const response = await axios.get(`${process.env.REACT_APP_API}/template`);

      const { templates } = response.data;

      // Function to filter template /only list approved templates
      const approvedTemplates = templates?.data?.filter(
         (temp) => temp.status === "APPROVED"
      );
      setTemplates(approvedTemplates);
   };

   useEffect(() => {
      if (openTemplateList) {
         getAllTemplates();
      }
   }, [openTemplateList]);
   return (
      <Box>
         <Box
            sx={{
               position: "absolute",
               width: "600px",
               height: "400px",
               bgcolor: "#fff",
               left: 0,
               top: -400,
               borderRadius: "10px",
               boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
         >
            <Box height="35px" m={2} borderBottom="1px solid #80808026 " display="flex" justifyContent="space-between">
               <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontWeight: "600" }}
               >
                  Templates
               </Typography>
               <Button onClick={()=>setOpenTemplateList(false)}>
                  <CloseIcon />
               </Button>
            </Box>
            <Box
               sx={{
                  overflowY: "scroll",
                  backgroundColor: "whitesmoke",
                  borderRadius: "15px",
               }}
               maxHeight="320px"
               m={1}
            >
               {templates?.map((item) => (
                  <Box
                     padding="5px"
                     m={1.5}
                     bgcolor="#edd6ab94"
                     sx={{
                        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        cursor: "pointer",
                     }}
                     onClick={() => handleOpenTemplateModal(item)}
                  >
                     <Typography
                        key={item.name}
                        sx={{
                           fontWeight: "600",
                           fontSize: "14px",
                           // ":hover": {
                           //   fontWeight: "600",
                           // },
                        }}
                     >
                        {item?.name}
                     </Typography>
                     {item.components.find(
                        (temp) => temp.type === "HEADER"
                     ) && (
                        <Typography fontSize="14px">
                           <span style={{ fontSize: "12px", color: "grey" }}>
                              Header:
                           </span>
                           {
                              item?.components.find(
                                 (temp) => temp.type === "HEADER"
                              )?.text
                           }
                        </Typography>
                     )}
                     {item.components.find((temp) => temp.type === "BODY") && (
                        <Typography fontSize="14px">
                           <span style={{ fontSize: "12px", color: "grey" }}>
                              Body:{" "}
                           </span>
                           {
                              item?.components.find(
                                 (temp) => temp.type === "BODY"
                              )?.text
                           }
                        </Typography>
                     )}
                     {item.components.find(
                        (temp) => temp.type === "FOOTER"
                     ) && (
                        <Typography fontSize="14px">
                           <span style={{ fontSize: "12px", color: "grey" }}>
                              Footer:
                           </span>
                           {
                              item?.components.find(
                                 (temp) => temp.type === "FOOTER"
                              )?.text
                           }
                        </Typography>
                     )}
                     {item.components.find(
                        (temp) => temp.type === "BUTTONS"
                     ) && (
                        <Typography fontSize="14px">
                           <span style={{ fontSize: "12px", color: "grey" }}>
                              Footer:
                           </span>
                           {
                              item?.components.find(
                                 (temp) => temp.type === "BUTTONS"
                              )?.text
                           }
                        </Typography>
                     )}
                  </Box>
               ))}
            </Box>
         </Box>
         <TemplateViewModal
            setMessageList={setMessageList}
            openTemplate={openTemplate}
            handleClose={handleCloseTemplateModal}
            selectedTemplate={selectedTemplate}
            setOpenTemplateList={setOpenTemplateList}
            selectedChat={selectedChat}
          
         />
      </Box>
   );
}

export default ListTemplate;
