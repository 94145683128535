import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import BulkSendStepper from "./BulkSendStepper";
import { useDispatch, useSelector } from "react-redux";
import { getAllContacts } from "../../../store/slices/contactSlice";

function BulkSendModal({ open, handleClose, allContacts }) {
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState([]);
  const { contacts } = useSelector((state) => state.contacts);
  console.log("bulk send..............");

  const getAllTemplates = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API}/template`);

    const { templates } = response.data;
    setTemplates(templates.data);
  };
  useEffect(() => {
    if (open) {
      getAllTemplates();
      dispatch(getAllContacts());
    }
  }, [open]);
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Bulk Send Templates</DialogTitle>
      <DialogContent>
        <BulkSendStepper handleClose={handleClose} templates={templates} allContacts={contacts} />
        <DialogContentText id="alert-dialog-description"></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BulkSendModal;
