import React, { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import axios from "axios";
import WhatsappContainer from "../../components/whatsapp/WhatsappContainer";
import Sidebar from "../../components/dashboard/Sidebar";
import { Box } from "@mui/material";
import Footer from "../../components/dashboard/Footer";
import DrawerSidebar from "../../components/common/DrawerSidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addNotificaton } from "../../store/slices/notificationSlice";
import {
   getAllChats,
   updateNewMessageCount,
} from "../../store/slices/chatSlice";
import {
   updateMessageStatus,
   updateNewMessage,
   updateReceivedMsgTime,
} from "../../store/slices/messageSlice";

//Import whatsapp notification audio
import notificationSound from "../../assets/audios/whatsapp_notification.mp3";
import { getAllTemplates } from "../../store/slices/templateSlice";
//Set the socket URL

const url = process.env.REACT_APP_SOCKET_URL;

function WhatsappChat() {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { selectedChat } = useSelector((state) => state?.messages);
   const [selected, setSelected] = useState({});
   const [newChat, setNewChat] = useState({});
   const selectedChatRef = useRef(selectedChat);

   useEffect(() => {
      selectedChatRef.current = selectedChat;
   }, [selectedChat]);

   const getNewlyReceivedMessage = async (messageId) => {
      try {
         const newMessage = await axios.get(
            `${process.env.REACT_APP_API}/messages/last-messages/${messageId}?`
         );
         // console.log(newMessage.data.lastMessages);

         const msg = newMessage.data.lastMessages;
         dispatch(updateNewMessage({ newMessage: msg }));
      } catch (err) {
         console.log(err);
      }
   };
   useEffect(() => {
      const isLogin = JSON.parse(localStorage.getItem("login"));
      
      if (!isLogin) {
         navigate("/");
         }
      dispatch(getAllTemplates());
   }, []);

   useEffect(() => {
      const socket = io(url);
      const connectHandler = () => {
         console.log("connected to websocket");
      };
      try {
         socket.on("connect", connectHandler);

         // SOCKET FOR IF A NEW MESSAGE RECEIVED
         socket.on("newMessage", (message) => {
            const { chatId, count, messageId } = message;
            dispatch(getAllChats());

            dispatch(updateNewMessageCount(message));

            setTimeout(() => {
               if (selectedChatRef.current?._id === chatId) {
                  getNewlyReceivedMessage(messageId);
               }
            }, 1000);
            // setNewReceivedMessage(message);
            // dispatch(addNotificaton(message));
            const sound = new Audio(notificationSound);
            sound.play();
         });

         //==== SOCKET FOR IF A NEW CHAT CREATED ===
         socket.on("newChat", (chat) => {
            setNewChat(chat);
            dispatch(addNotificaton(chat._id));
            dispatch(getAllChats());
            const sound = new Audio(notificationSound);
            sound.play();
         });

         //=== SOCKET FOR WHEN RECEIVED MSG TIME IS UPDATED ======
         socket.on("lastMessageTime", (chat) => {
            const { chatId, fullDocument } = chat;
            if (selectedChatRef.current?._id === chatId) {
               const { last_msg_received_at } = fullDocument;
               dispatch(updateReceivedMsgTime(last_msg_received_at));
            }

            // dispatch(updateLastRecivedMsgTime());
         });

         //======  SOCKET FOR IF THE MESSAGE STATUS UPDATED =====
         socket.on("statusChange", (message) => {
            if (selectedChatRef.current?._id === message.chat_id) {
               dispatch(updateMessageStatus(message));
            }
         });
      } catch (err) {
         console.log("error", err);
      }

      // Cleanup function
      return () => {
         socket.disconnect(); // Disconnect the socket when the component unmounts
      };
   }, []);

   return (
      <>
         <Box
            sx={{
               // backgroundColor: "#e0e3e7",
               display: "flex",
            }}
         >
            <DrawerSidebar />
            <Sidebar />
            {/* no need to pass from here directly update to store */}
            <WhatsappContainer
            // newReceicedMessage={newReceicedMessage}
            // newChat={newChat}
            />
         </Box>
         {/* <Footer /> */}
      </>
   );
}

export default WhatsappChat;
