import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllTemplates = createAsyncThunk(
   "template/getAllTemplates",
   async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API}/template`
         );
         const { templates } = response.data;
        
         return templates.data;
      } catch (err) {
         console.log(err);
         throw err
      }
   }
);

const initialState = {
   templates: [],
   selectedTemplate: {},
   loading: false,
   error: null,
};
const templateSlice = createSlice({
   name: "template",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(getAllTemplates.pending, (state) => {
            state.loading = true;
            state.error = null;
         })
         .addCase(getAllTemplates.fulfilled, (state, action) => {
            state.loading = false;
            state.templates = action.payload;
            state.error = null;
         })
         .addCase(getAllTemplates.rejected, (state,action) => {
            state.loading = false;
            state.error=action.error.message
         });
   },
});

export default templateSlice.reducer;
