import {
   Box,
   Button,
   Card,
   CardContent,
   Grid,
   Typography,
   styled,
} from "@mui/material";
import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch } from "react-redux";
import { getInsights } from "../../../store/slices/insightsSlice";

const CustomDatePicker = styled(DatePicker)`
  .MuiInputBase-root {
    height: 40px; /* Adjust the height as needed */
    overflow: hidden; /* Hide overflow */
  }
  .MuiInput-input::placeholder {
    text-align: center; /* Center-align the placeholder text */
  }

  .MuiPickersPopper-container {
    height: auto; /* Adjust the calendar pop-up height as needed */
    overflow: hidden; /* Hide overflow */
  }
`;

function InsightsComponent({ reports }) {
   const [startDate, setStartDate] = useState(null);
   const [endDate, setEndDate] = useState(null);
   const dispatch = useDispatch();

   console.log(startDate, endDate);

   const handleFilterReport = () => {
      const formatedStart = startDate?.format("YYYY-MM-DD");
      const formatedEnd = endDate?.format("YYYY-MM-DD");

      dispatch(getInsights({ startDate: formatedStart, endDate: formatedEnd }));
   };

   const clearFilter = () => {
      setStartDate(null);
      setEndDate(null);
      dispatch(getInsights({ startDate: "", endDate: "" }));
   };

   return (
      <Box mt={8} ml={5} mr={4} width="100%">
         <Typography fontSize="20px" fontWeight="600" mt={2}>
            Insights
         </Typography>
         <Box sx={{ display: "flex", alignItems: "center" }} gap={2} mt={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
               <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <CustomDatePicker
                     label="Start Date"
                     value={startDate}
                     onChange={(newValue) => setStartDate(newValue)}
                  />
               </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
               <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <CustomDatePicker
                     label="End Date"
                     value={endDate}
                     onChange={(newValue) => setEndDate(newValue)}
                  />
               </DemoContainer>
            </LocalizationProvider>
            <Button
               variant="contained"
               sx={{ color: "black",  marginTop:"6px" }}
               onClick={handleFilterReport}
            >
               Filter
            </Button>
            {(startDate || endDate) && (
               <Button
                  variant="outlined"
                  sx={{ color: "black",  marginTop:"6px" }}
                  onClick={() => clearFilter()}
               >
                  Clear
               </Button>
            )}
         </Box>

         {/* <Box
            width="100%"
            height="100px"
            bgcolor="grey"
            display="flex"
            justifyContent="space-between"
         >
            <Card sx={{ width:"350px", boxShadow: 3 }}>
               <CardContent>
                  <Typography variant="h6" gutterBottom>
                     Total Chat
                  </Typography>
                  <Typography variant="h4">25</Typography>
               </CardContent>
            </Card>
            <Card sx={{ maxWidth: 275, boxShadow: 3 }}>
               <CardContent>
                  <Typography variant="h6" gutterBottom>
                     Total Chat
                  </Typography>
                  <Typography variant="h4">25</Typography>
               </CardContent>
            </Card>
            <Card sx={{ maxWidth: 275, boxShadow: 3 }}>
               <CardContent>
                  <Typography variant="h6" gutterBottom>
                     Total Chat
                  </Typography>
                  <Typography variant="h4">25</Typography>
               </CardContent>
            </Card>
            <Card sx={{ maxWidth: 275, boxShadow: 3 }}>
               <CardContent>
                  <Typography variant="h6" gutterBottom>
                     Total Chat
                  </Typography>
                  <Typography variant="h4">25</Typography>
               </CardContent>
            </Card>
            <Card sx={{ maxWidth: 275, boxShadow: 3 }}>
               <CardContent>
                  <Typography variant="h6" gutterBottom>
                     Total Chat
                  </Typography>
                  <Typography variant="h4">25</Typography>
               </CardContent>
            </Card>
         </Box> */}
         <Grid container spacing={3} mt={2}>
            {/* {[...Array(6)].map((_, index) => ( */}
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
               <Card sx={{ width: "100%", boxShadow: 3 }}>
                  <CardContent>
                     <Typography variant="h6" gutterBottom>
                        Total Chat
                     </Typography>
                     <Typography variant="h4">
                        {reports?.totalChatCount}
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
               <Card sx={{ width: "100%", boxShadow: 3 }}>
                  <CardContent>
                     <Typography variant="h6" gutterBottom>
                        Incoming
                     </Typography>
                     <Typography variant="h4">
                        {reports?.incoming || 0}
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
               <Card sx={{ width: "100%", boxShadow: 3 }}>
                  <CardContent>
                     <Typography variant="h6" gutterBottom>
                        Outgoing
                     </Typography>
                     <Typography variant="h4">
                        {reports?.outgoing || 0}
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
               <Card sx={{ width: "100%", boxShadow: 3 }}>
                  <CardContent>
                     <Typography variant="h6" gutterBottom>
                        Not replied
                     </Typography>
                     <Typography variant="h4">
                        {reports?.notRepliedChatCount}
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
               <Card sx={{ width: "100%", boxShadow: 3 }}>
                  <CardContent>
                     <Typography variant="h6" gutterBottom>
                        Total Chat
                     </Typography>
                     <Typography variant="h4">25</Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
               <Card sx={{ width: "100%", boxShadow: 3 }}>
                  <CardContent>
                     <Typography variant="h6" gutterBottom>
                        Total Chat
                     </Typography>
                     <Typography variant="h4">25</Typography>
                  </CardContent>
               </Card>
            </Grid>
            {/* ))} */}
         </Grid>
      </Box>
   );
}

export default InsightsComponent;
