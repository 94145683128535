import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   FormControl,
   Input,
   InputAdornment,
   InputLabel,
   TextField,
   Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import axios from "axios";

function BulkTemplateViewModal({
   openTemplate,
   handleClose,
   selectedTemplate,
   setNewTemplateData,
   handleNext,
}) {
   const components = selectedTemplate?.components || [];

   const fileRef = useRef(null);
   const [file, setFile] = useState("");
   const [bodyContent, setBodyContent] = useState("");
   // State for handling if the header opion is link
   const [linkOption, setLinkOpen] = useState(false);
   // const [mediaLink, setMediaLink] = useState("");
   const [mediaUrl, setMediaUrl] = useState("");
   const [footerContent, setFooterContent] = useState({});
   const [previewImage, setPreviewImage] = useState("");
   const [variable, setVariable] = useState([]);
   const [validationError, setValidationError] = useState("");
   const [button, setButton] = useState([]);
   const [headerType, setHeaderType] = useState("");
   const [headerText, setHeaderText] = useState("");
   const [urlButton, setUrlButton] = useState("");

   useEffect(() => {
      if (!selectedTemplate) return;

      const components = selectedTemplate.components || [];
      const bodyComponent = components.find((item) => item.type === "BODY");
      const headerContent = components.find((item) => item.type === "HEADER");

      if (headerContent) {
         setHeaderType(headerContent.format);
      }
      if (bodyComponent) {
         const bodyText = bodyComponent.text || "";
         const regex = /\{\{\d+\}\}/g;

         // Use match() to find all occurrences of the pattern in the text
         const matches = bodyText.match(regex) || [];
         if (matches.length > 0) setBodyContent(true);

         // Map matches to variable objects
         const allVariables = matches.map((item) => ({
            id: item,
            type: "text",
            text: "",
         }));

         // Update state with the extracted variables
         setVariable(allVariables);
      }
      const buttonContent = components.find((item) => item.type === "BUTTONS");

      if (buttonContent) {
         let newButtons = [];
         buttonContent?.buttons?.map((btn, index) => {
            newButtons.push({ ...btn, index });
         });

         setButton(newButtons);
      }
   }, [components]);

   // Function to send template message
   const handleSendMessage = async () => {
      if (variable) {
         const variableWithoutText = variable.filter(
            (item) => item.text === ""
         );

         if (variableWithoutText.length > 0)
            return setValidationError("All fields required");

         setValidationError("");
      }
      let formData = new FormData();

      if (file) {
         formData.append("image", file);
      }

      if (bodyContent) {
         const bodyArray = [];

         // Add each object from 'variable' array to 'bodyArray'
         variable.forEach((obj) => {
            bodyArray.push(obj);
         });

         // Append 'bodyArray' as a single entry named 'body'
         formData.append("body", JSON.stringify(bodyArray));
      }

      if (footerContent) {
         const footer = {
            type: "text",
            text: "a sample body text",
         };
         formData.append("footer", footer);
      }
      if (urlButton) {
         const dynamicUrlBtn = button?.find((btn) => btn.type === "URL");
         let buttonComponent = {
            type: "button",
            sub_type: "url",
            index: dynamicUrlBtn?.index,
            parameters: [
               {
                  type: "payload",
                  payload: urlButton,
               },
            ],
         };
         formData.append("button", JSON.stringify(buttonComponent));
      }
      // const to = 919446767626;
      const to = 916238802949;
      const template = JSON.stringify({
         // templateName: selectedTemplate?.name,/
         templateName: selectedTemplate?.name,
         languageCode: selectedTemplate?.language,
      });

      // formData.append("template", true);
      formData.append("template", template);
      formData.append("to", to);
      formData.append("type", "template");

      setNewTemplateData(formData);
      handleNext();

      // try {
      //   const response = await axios.post(
      //     `${process.env.REACT_APP_API}/messages/send`,
      //     formData
      //   );
      //   console.log(response.data);
      //   if (response && response?.data?.status === "success") {
      //     handleClose();
      //   }
      // } catch (err) {
      //   console.log(err);
      // }
   };

   const fileUploadRef = useRef(null);
   const handleUploadFile = () => {
      fileUploadRef.current.click();
   };

   const handleChange = (e, itemId) => {
      const newText = e.target.value;

      const result = variable.map((vari) => {
         if (vari.id === itemId) {
            vari.text = newText;
         }
         return vari;
      });
      setVariable(result);
   };

   const handleFileinputChange = (e) => {
      let file = e.target.files[0];
      if (file) {
         setFile(file);
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onloadend = () => {
            setPreviewImage(reader.result);
         };
      }
   };
   return (
      <>
         <Box sx={{ width: "500px" }}>
            <p>{validationError}</p>
            <Typography fontSize="14px">{selectedTemplate?.name}</Typography>
            {/**Header */}
            {/* {components?.find((item) => item.type === "HEADER") && (
               <Box bgcolor="#48f39d40" p={1} height="150px" borderRadius="5px">
                  <Typography fontSize="12px">Header</Typography>
                  {!linkOption ? (
                     <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                     >
                        <Box
                           width="100%"
                           display="flex"
                           flexDirection="column"
                           alignItems="center"
                        >
                           {previewImage ? (
                              <Box sx={{ width: "100px", display: "flex" }}>
                                 <img
                                    src={previewImage}
                                    alt="uploaded"
                                    style={{ width: "100%" }}
                                 />
                                 <HighlightOffRoundedIcon
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => setPreviewImage(null)}
                                 />
                              </Box>
                           ) : (
                              <>
                                 <CloudUploadIcon
                                    sx={{ fontSize: "50px", cursor: "pointer" }}
                                    onClick={handleUploadFile}
                                 />
                                 <Typography>Upload a file</Typography>
                              </>
                           )}
                           <input
                              ref={fileUploadRef}
                              type="file"
                              value=""
                              style={{ display: "none" }}
                              onChange={handleFileinputChange}
                           />
                        </Box>
                        {!previewImage && (
                           <Box
                              width="100%"
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                           >
                              <InsertLinkIcon
                                 onClick={() => setLinkOpen(true)}
                                 sx={{ fontSize: "50px", cursor: "pointer" }}
                              />
                              <Typography>Enter a link to file</Typography>
                           </Box>
                        )}
                     </Box>
                  ) : (
                     <TextField
                        sx={{ marginTop: "20px" }}
                        id=""
                        label="URL"
                        value={mediaUrl}
                        fullWidth
                        onChange={(e) => setMediaUrl(e.target.value)}
                     />
                  )}
               </Box>
            )} */}
            {headerType && headerType !== "TEXT" && (
               <Box bgcolor="#48f39d40" p={1} height="150px" borderRadius="5px">
                  <Typography fontSize="12px">Header: {headerType}</Typography>
                  {!linkOption ? (
                     <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                     >
                        <Box
                           width="100%"
                           display="flex"
                           flexDirection="column"
                           alignItems="center"
                        >
                           {previewImage ? (
                              <Box sx={{ width: "100px", display: "flex" }}>
                                 <img
                                    src={previewImage}
                                    alt="uploaded"
                                    style={{ width: "100%" }}
                                 />
                                 <HighlightOffRoundedIcon
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => setPreviewImage(null)}
                                 />
                              </Box>
                           ) : (
                              <>
                                 <CloudUploadIcon
                                    sx={{
                                       fontSize: "50px",
                                       cursor: "pointer",
                                    }}
                                    onClick={handleUploadFile}
                                 />
                                 <Typography>Upload a file</Typography>
                              </>
                           )}
                           <input
                              ref={fileUploadRef}
                              type="file"
                              accept={
                                 headerType === "IMAGE"
                                    ? "image/*"
                                    : headerType === "VIDEO"
                                    ? "video/*"
                                    : ""
                              }
                              value=""
                              style={{ display: "none" }}
                              onChange={handleFileinputChange}
                           />
                        </Box>
                        {/* {!previewImage && (
                              <Box
                                 width="100%"
                                 display="flex"
                                 flexDirection="column"
                                 alignItems="center"
                              >
                                 <InsertLinkIcon
                                    onClick={() => setLinkOpen(true)}
                                    sx={{ fontSize: "50px", cursor: "pointer" }}
                                 />
                                 <Typography>Enter a link to file</Typography>
                              </Box>
                           )} */}
                     </Box>
                  ) : (
                     <TextField
                        sx={{ marginTop: "20px" }}
                        id=""
                        label="URL"
                        value={mediaUrl}
                        fullWidth
                        onChange={(e) => setMediaUrl(e.target.value)}
                     />
                  )}
               </Box>
            )}
            {headerType && headerType === "TEXT" && (
               <Box
                  mt={1}
                  bgcolor="#48f39d40"
                  p={1}
                  // height="150px"
                  borderRadius="5px"
               >
                  <Typography fontSize="12px">Header</Typography>
                  <Typography>{headerText}</Typography>
               </Box>
            )}

            {/**Body */}
            {components?.find((item) => item.type === "BODY") && (
               <Box
                  mt={1}
                  bgcolor="#48f39d40"
                  p={1}
                  // height="150px"
                  borderRadius="5px"
               >
                  <Typography fontSize="12px">Body</Typography>
                  <Typography>
                     {components?.find((item) => item.type === "BODY")?.text}
                  </Typography>
                  {variable?.map((item) => (
                     <FormControl
                        fullWidth
                        sx={{ m: 1 }}
                        variant="standard"
                        key={item?.id}
                     >
                        <Input
                           id="standard-adornment-amount"
                           onChange={(e) => handleChange(e, item?.id)}
                           startAdornment={
                              <InputAdornment position="start">
                                 {item?.id}
                              </InputAdornment>
                           }
                        />
                     </FormControl>
                  ))}
               </Box>
            )}

            {/**Footer */}
            {components?.find((item) => item.type === "FOOTER") && (
               <Box mt={1} bgcolor="#48f39d40" p={1} borderRadius="5px">
                  <Typography fontSize="12px" color="initial">
                     footer
                  </Typography>
                  <Typography fontSize="15px">
                     {components?.find((item) => item.type === "FOOTER")?.text}
                  </Typography>
               </Box>
            )}

            {/**Buttons */}
            {components?.find((item) => item.type === "BUTTONS") && (
               <Box
                  mt={1}
                  bgcolor="#48f39d40"
                  p={1}
                  // height="150px"
                  borderRadius="5px"
               >
                  <Typography fontSize="12px">Button </Typography>
                  <Box display="flex" flexDirection="column">
                     {button?.map((btn) => (
                        <>
                           <Typography fontSize="12px" fontWeight={300}>
                              Type: {btn?.type}
                           </Typography>
                           <Box mt={1}>
                              <Button
                                 variant="outlined"
                                 sx={{
                                    color: "black",
                                    textTransform: "capitalize",
                                 }}
                              >
                                 {btn?.text}
                              </Button>
                              <Typography fontSize="12px">
                                 {btn?.url || btn?.phone_number}
                              </Typography>
                              <br />
                              {btn.example && (
                                 <TextField
                                    label="{{1}}"
                                    value={urlButton}
                                    onChange={(e) =>
                                       setUrlButton(e.target.value)
                                    }
                                    size="small"
                                 />
                              )}
                           </Box>
                        </>
                     ))}
                     {/* {button?.map((btn) => (
                <TextField
                  sx={{ mt: 1 }}
                  key={btn?.text}
                  id=""
                  label={btn?.type}
                  value={btn.value}
                  onChange={(e) => handleButtonChange(e, btn.text)}
                />
              ))} */}
                  </Box>
               </Box>
            )}
         </Box>

         <DialogActions>
            <Button
               onClick={handleSendMessage}
               variant="contained"
               sx={{
                  backgroundColor: "black",
                  textTransform: "capitalize",
                  ":hover": {
                     bgcolor: "#000000bd",
                  },
               }}
            >
               Save & Next
            </Button>
         </DialogActions>
      </>
   );
}

export default BulkTemplateViewModal;
