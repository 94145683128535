import axios from "axios";
import { date } from "yup";

export const sentOptToEmail = async (data) => {
   try {
      const response =await axios.post(
         `${process.env.REACT_APP_API}/user/send-otp`,
         data
      );
      return response.data;
   } catch (err) {
      console.log(err);
      return err;
   }
};

export const userVerifyOTP = async (data) => {
   try {
      const response =await axios.post(
         `${process.env.REACT_APP_API}/user/verify-otp`,
         data
      );
      return response.data;
   } catch (err) {
      console.log(err);
      return err;
   }
};

export const resetPassword = async (data) => {
    console.log({data})
   try {
      const response =await axios.post(
         `${process.env.REACT_APP_API}/user/reset-password`,
         data
      );
      return response.data;
   } catch (err) {
      console.log(err);
      return err;
   }
};

export const getAdminDetails = async () => {
   try {
      const { token } = JSON.parse(localStorage.getItem("admin-auth"));
      const res = await axios.get(
         `${process.env.REACT_APP_SERVER_URL}/admin/admin-data`,
         {
            headers: { Authorization: `Bearer ${token}` },
         }
      );

      return res.data;
   } catch (err) {
      console.log(err);
   }
};
