import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";

const MainBox = styled(Box)({
  borderRadius: "15px",
  backgroundColor: "#fff",
  //   padding: '25px',
  //   maxHeight: "430px",
  //   height: "430px",
  overflowY: "scroll",
});
const OuterBox = styled(Box)({
  width: "100%",
  height: "auto",
  borderTop: "1px solid grey",
});
const CustomTableCell = styled(TableCell)({
  color: "white",
  fontSize: "14px",
  fontWeight: "400",
  borderRight: "1px solid white",
  textAlign: "center",
});

function BulkSendSingleView() {
  const [selectedReport, setSelectReport] = useState([]);
  const params = useParams();
  const date = moment(selectedReport?.data?.createdAt).format("DD/MM/YYYY");
  const getDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };
  const getTime = (date) => moment(date).format("h:mm a");



  const getReportByReportId = async (reportId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/reports/bulksend-report/${reportId}`);

      if (response && response.data?.status === "success") {
        setSelectReport(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (params) {
      getReportByReportId(params.id);
    }
  }, [params]);
  return (
    <OuterBox mt={8} ml={5} mr={4}>
      <Toaster />
      <Typography fontSize="20px" fontWeight="600" mt={2}>
        Bulk Send
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="end">
        <Box>
          <Typography fontSize="14px" fontWeight="400" mt={2} lineHeight={0.5}>
            Template Name:{selectedReport[0]?.template_name}
          </Typography>
          <Typography fontSize="14px" fontWeight="400" mt={2} lineHeight={1}>
            Date :{date}
          </Typography>
        </Box>
        <Typography fontSize="14px" fontWeight="400" mt={2} lineHeight={1}>
          Total Count :{selectedReport.length}
        </Typography>
      </Box>
      <MainBox mt={2}>
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                background: "#8EB7FF",
                color: "#fff",
                height: "56px",
              }}
            >
              <TableRow
                sx={{
                  height: "20px",
                  "& .MuiTableRow-root": {
                    height: "20px",
                  },
                }}
              >
                <CustomTableCell>Date & Time</CustomTableCell>
                <CustomTableCell>Contact Number</CustomTableCell>
                <CustomTableCell>Send Time</CustomTableCell>
                <CustomTableCell>Delivered</CustomTableCell>
                <CustomTableCell>Read</CustomTableCell>
                <CustomTableCell>Failed</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedReport?.map((item) => (
                <TableRow
                  key={item?._id}
                  sx={{
                    "& .MuiTableCell-root": {
                      border: "1px solid #8080803d",
                      textAlign: "center",
                    },
                  }}
                >
                  <TableCell>{getDate(item?.createdAt)}</TableCell>
                  <TableCell>{item?.display_phone_number}</TableCell>
                  <TableCell> {getTime(item?.createdAt)}</TableCell>
                  <TableCell>{item?.status?.status === 2 ? <DoneIcon sx={{ fontSize: "15px" }} /> : "-"}</TableCell>
                  <TableCell align="center">{item?.status?.status === 3 ? <DoneIcon sx={{ fontSize: "15px" }} /> : "-"}</TableCell>
                  <TableCell width="50px">{item?.status?.status === 4 ? <DoneIcon sx={{ fontSize: "15px" }} /> : "-"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </MainBox>
    </OuterBox>
  );
}

export default BulkSendSingleView;
