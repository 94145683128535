import {
   Box,
   Button,
   FormControl,
   FormHelperText,
   Grid,
   Input,
   InputLabel,
   MenuItem,
   Select,
   TextField,
   ToggleButton,
   ToggleButtonGroup,
   Typography,
   styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import EmojiDialog from "./EmojiDialog";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CodeIcon from "@mui/icons-material/Code";

import languages from "../../constant/languages";
import PreviewBox from "./PreviewBox";
import TemplatePreviewModal from "./TemplatePreviewModal";
import { useNavigate } from "react-router-dom";

const MainBox = styled(Box)({
   borderRadius: "15px",
   backgroundColor: "#fff",
   padding: "25px",
   overflowY: "scroll",
});
const OuterBox = styled(Box)({
   width: "100%",
   height: "auto",
   borderTop: "1px solid grey",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
   marginTop: "20px",
   color: "black",
   "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: "#000000c4",
      color: "white",
   },
   "& .Mui-selected:hover": { backgroundColor: "#000000c4" },
});
const EmojiBox = styled(Box)({
   width: "40px",
   height: "40px",
   backgroundColor: "#6c757d",
   display: "flex",
   alignItems: "center",
   borderRadius: "10px",
   justifyContent: "center",
   marginLeft: "5px",
});

const CustomBtn = styled(Button)({
   borderRadius: "5px",
   color: "#ffff",
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 250,
      },
   },
};

function MainContainer() {
   const [examples, setExamples] = useState([]);
   const [file, setFile] = useState([]);
   const [bodyText, setBodyText] = useState("");
   const [header, setHeader] = useState("text");
   const [preview, setPreview] = useState(false);
   const [headerText, setHeaderText] = useState("");
   const [formats, setFormats] = useState(() => []);
   const [openEmoji, setOpenEmoji] = useState(false);
   const [buttonCount, setButtonCount] = useState([]);
   const [vaiableCount, setVariableCount] = useState(1);
   const [selectedText, setSelectedText] = useState("");
   const [footerContent, setFooterContent] = useState("");
   const [selectedCategory, setSelectedCategory] = useState("");
   const [templateType, setTemplateType] = useState("standard");
   const [selectedLanguage, setSelectedLanguage] = useState("en");
   const [selectedTexPosition, setSelectedTexPosition] = useState({});
   const [templateDetails, setTemplateDetails] = useState({});
   const [isVariableExist, setIsVariableExist] = useState(false);
   const [validationError, setValidationError] = useState({
      button: "",
      header: "",
      body: "",
      footer: "",
   });
   const [contentLength, setContentLength] = useState({
      header: 0,
      body: 0,
      footer: 0,
   });

   const navigate = useNavigate();
   // Function which handle the only selected text from the body
   const handleSelection = () => {
      const textField = document.getElementById("outlined-multiline-static");
      const selectionStart = textField.selectionStart;
      const selectionEnd = textField.selectionEnd;
      const selectedText = bodyText.substring(selectionStart, selectionEnd);
      setSelectedText(selectedText);
      setSelectedTexPosition({ start: selectionStart, end: selectionEnd });
   };
   // Function to update the body content
   const handleBodyChange = (e) => {
      const newBodyText = e.target.value;
      setBodyText(newBodyText);
      setTimeout(() => {
         setContentLength((prev) => ({ ...prev, body: newBodyText.length }));
      }, 0);
      if (newBodyText.length > 0)
         setValidationError((prev) => ({ ...prev, body: "" }));
   };

   //Function to add variable
   const addVariable = () => {
      setVariableCount((prev) => prev + 1);
      setBodyText((prev) => prev + `{{${vaiableCount}}}`);
   };

   //Function to select language
   const handleSelectLanguage = (e) => {
      setSelectedLanguage(e.target.value);
   };

   //Function to close emoji dialogue box
   const handleCloseDialogue = () => setOpenEmoji(false);

   //Function to open emoji dialogue box
   const handleOpenDialogue = () => setOpenEmoji(true);

   // Function to change the message format options
   const handleFormat = (event, newFormats) => setFormats(newFormats);

   //Function to close template preview modal
   const closePreviewModel = () => setPreview(false);

   const handleChangeTemplate = (e) => {
      setTemplateType(e.target.value);
   };

   // Function to replace the word

   const replaceWordHandler = (replace) => {
      const before = bodyText.substring(0, selectedTexPosition.start);
      const after = bodyText.substring(selectedTexPosition.end);
      setBodyText(before + replace + selectedText + replace + after);
   };

   //Function to change the style of the body text
   const handleBodyTextStyle = (event, style) => {
      if (!selectedText) return;
      const selectedStyle = style[0];
      switch (selectedStyle) {
         case "bold":
            replaceWordHandler("*");
            break;
         case "italic":
            replaceWordHandler("_");
            break;
         case "strike":
            replaceWordHandler("~");
            break;
         default:
            replaceWordHandler("```");
      }
      setSelectedText("");
   };

   const handleChangeHeader = (e) => {
      const newHeader = e.target.value;

      setHeader(newHeader);
   };

   //Function to open template preview

   const handlePreviewTemplate = () => setPreview(true);

   const handleNameChange = (e) => {};

   // Function to set template header content
   const handleHederContent = (e) => {
      const newHeader = e.target.value;
      setContentLength((prev) => ({ ...prev, header: newHeader.length }));
      setHeaderText(e.target.value);
      if (newHeader.length > 0)
         setValidationError((prev) => ({ ...prev, header: "" }));
   };

   // Function to set template footer content
   const handleFooterChange = (e) => {
      const newFooter = e.target.value;
      setContentLength((prev) => ({ ...prev, footer: newFooter.length }));
      setFooterContent(newFooter);
      if (newFooter.length > 0)
         setValidationError((prev) => ({ ...prev, footer: "" }));
   };

   // TEMPLATE CREATE FUNCTION
   const handleCreateTemplate = async () => {
      let example = {};

      let body_text = examples.map((item) => item.text);
      example.body_text = [body_text];

      templateDetails.components.map((items) => {
         if (items.type === "BODY" && isVariableExist) {
            items.example = example;
         }

         return items;
      });

      let templateData = { ...templateDetails };
      const formData = new FormData();

      if (file) {
         formData.append("image", file);
         if (header === "image") {
            formData.append("file", "DOCUMENT");
         }
         if (header === "document") {
            formData.append("file", "DOCUMENT");
         }
         if (header === "video") {
            formData.append("file", "VIDEO");
         }
      }
      formData.append("body_text", JSON.stringify(templateData));
      const config = {
         headers: {
            "Content-Type": "multipart/form-data", // Set content type for file upload
         },
      };

      try {
         const response = await axios.post(
            `${process.env.REACT_APP_API}/template`,
            formData,
            config
         );

         if (response && response?.data?.status === "success") {
            toast.success("Template created successfully");
            resetForm();
            setPreview(false);
            setHeaderText("");
            setVariableCount("");
            setButtonCount([]);
            setSelectedText("");
            setBodyText("");
            setFooterContent("");
            setFile("");
            setVariableCount("");
            // navigate("/create-template");
         }
      } catch (err) {
         console.log(err);
      }
   };

   const formik = useFormik({
      initialValues: {
         template_name: "",
         category_change: "",
      },
      validationSchema: Yup.object({
         template_name: Yup.string()
            .matches(
               /^[A-Za-z0-9_]+$/,
               "Only letters, numbers, and underscore are allowed"
            )
            .test(
               "no-spaces",
               "Spaces are not allowed",
               (value) => !/\s/.test(value)
            )
            .required("Required"),
         category_change: Yup.string().required("Required"),
      }),
      onSubmit: (values) => {
         const errors = {}; // Object to store validation errors

         // Validate body
         if (!bodyText) {
            errors.body = "Body cannot be empty";
         }

         // Validate header conditionally
         // if (formats.includes("header")) {
         //    errors.header = "Header cannot be empty";
         // }

         // Validate footer conditionally
         if (formats.includes("footer") && !footerContent) {
            errors.footer = "Footer cannot be empty";
         }

         // Set validation errors if any
         if (Object.keys(errors).length > 0) {
            setValidationError(errors);
            return; // Early exit on validation failure
         }


         // Build the object with components
         const obj = {
            name: values.template_name.toLowerCase(),
            language: selectedLanguage,
            category: selectedCategory,
            allow_category_change:
               values.category_change === "Yes" ? true : false,
            components: [],
         };

         if (headerText) {
            obj.components.push({
               type: "HEADER",
               format: "TEXT",
               text: headerText,
            });
         }

         // Add components conditionally
         if (bodyText) {
            obj.components.push({ type: "BODY", text: bodyText });
         }

         if (buttonCount.length > 0) {
            const buttonComponent = buttonCount.map((obj) => {
               const { id, ...rest } = obj;
               return rest;
            });
            obj.components.push({ type: "BUTTONS", buttons: buttonComponent });
         }

         handlePreviewTemplate();
         setTemplateDetails(obj);
      },
   });

   const {
      resetForm,
      setValues,
      errors,
      values,
      touched,
      handleSubmit,
      isSubmitting,
      handleChange,
   } = formik;

   // Function to handle add button
   const handleAddButton = () => {
      if (buttonCount?.length > 2) {
         setValidationError((prev) => ({
            ...prev,
            button: "Maximum button limit 3",
         }));
      } else {
         const newButton = {
            id: buttonCount?.length + 1,
            type: "quickReply",
         };

         setButtonCount((prev) => [...prev, newButton]);
      }
   };
   // Function to remove button
   const handleRemoveButton = (id) => {
      const newBtns = buttonCount?.filter((btn) => btn.id !== id);
      setButtonCount(newBtns);
   };

   //Function to change the button type
   const handleChangeButtonType = (e) => {
      const selectedId = Number(e.target.id);
      const selectedValue = e.target.value;
      const updatedButtons = buttonCount?.map((button) => {
         if (button.id === selectedId) {
            button.type = selectedValue;
         }
         return button;
      });
      setButtonCount(updatedButtons);
   };

   const handleLableChange = (e, id) => {
      const newLabel = e.target.value;
      const updatedButtons = buttonCount?.map((button) => {
         if (button.id === id) {
            button.text = newLabel;
         }
         return button;
      });
      setButtonCount(updatedButtons);
   };

   const handleButtonValue = (e, id) => {
      const value = e.target.value;
      const updatedButtons = buttonCount?.map((button) => {
         if (button.id === id) {
            if (button.type === "PHONE_NUMBER") {
               button.phone_number = value;
            } else {
               button.url = value;
            }
         }
         return button;
      });
      setButtonCount(updatedButtons);
   };

   const handleImageUpload = (e) => {
      setFile(e.target.files[0]);
   };
   const handleDocumentUpload = (e) => {
      setFile(e.target.files[0]);
   };

   return (
      <>
         <Toaster />
         <OuterBox mt={8} ml={5} mr={4}>
            <Typography fontSize="20px" fontWeight="600" mt={2}>
               New Template
            </Typography>
            <form onSubmit={formik.handleSubmit}>
               <MainBox mt={2}>
                  <Grid container>
                     <Grid item md={8}>
                        <Grid container spacing={3}>
                           <Grid item xs={12} md={6}>
                              <TextField
                                 id="template_name"
                                 fullWidth
                                 size="small"
                                 label="Template Name"
                                 variant="outlined"
                                 value={values.template_name}
                                 onChange={handleChange}
                                 helperText={
                                    touched.template_name &&
                                    errors.template_name
                                 }
                                 error={Boolean(
                                    touched.template_name &&
                                       errors.template_name
                                 )}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                 <InputLabel id="demo-simple-select-label">
                                    Category
                                 </InputLabel>
                                 <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="category"
                                    size="small"
                                    value={selectedCategory}
                                    label="Category"
                                    onChange={(e) =>
                                       setSelectedCategory(e.target.value)
                                    }
                                 >
                                    <MenuItem value="marketing">
                                       Marketing
                                    </MenuItem>
                                    <MenuItem value="utility">Utility</MenuItem>
                                    <MenuItem value="authentication">
                                       Authentication
                                    </MenuItem>
                                 </Select>
                              </FormControl>
                           </Grid>
                        </Grid>
                        <Grid container spacing={3} mt={3}>
                           <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                 <InputLabel id="demo-simple-select-label">
                                    Allow Category Change
                                 </InputLabel>
                                 <Select
                                    labelId="demo-simple-select-label"
                                    id="category_change"
                                    name="category_change"
                                    size="small"
                                    value={values.category_change}
                                    label="Allow Category Change"
                                    onChange={handleChange}
                                    error={Boolean(
                                       touched.category_change &&
                                          errors.category_change
                                    )}
                                    helpertext={
                                       touched.category_change &&
                                       errors.category_change
                                    }
                                 >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                 </Select>
                                 {touched.category_change &&
                                    errors.category_change && (
                                       <FormHelperText error>
                                          {errors.category_change}
                                       </FormHelperText>
                                    )}
                              </FormControl>
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                 <InputLabel id="demo-simple-select-label">
                                    Languages
                                 </InputLabel>
                                 <Select
                                    size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedLanguage}
                                    label="Languages"
                                    onChange={handleSelectLanguage}
                                    defaultValue=""
                                    MenuProps={MenuProps}
                                 >
                                    {Object?.entries(languages).map(
                                       ([key, value]) => (
                                          <MenuItem key={key} value={key}>
                                             {value.name + `(${key})`}
                                          </MenuItem>
                                       )
                                    )}
                                 </Select>
                              </FormControl>
                           </Grid>
                        </Grid>
                        <Box mt={3}>
                           <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                 Template Type
                              </InputLabel>
                              <Select
                                 size="small"
                                 labelId="demo-simple-select-label"
                                 id="demo-simple-select"
                                 name="template_type"
                                 value={templateType}
                                 label="Template Type"
                                 disabled={
                                    selectedCategory === "authentication"
                                 }
                                 onChange={handleChangeTemplate}
                              >
                                 <MenuItem value="standard">
                                    Standard (Text only)
                                 </MenuItem>
                                 <MenuItem value="interactive">
                                    Media & Interactive
                                 </MenuItem>
                              </Select>
                           </FormControl>
                        </Box>
                        {templateType !== "standard" && (
                           <Box mt={3}>
                              <Typography fontWeight="600" fontSize="16px">
                                 Select message template building blocks
                              </Typography>
                              <StyledToggleButtonGroup
                                 value={formats}
                                 size="small"
                                 onChange={handleFormat}
                                 aria-label="text formatting"
                              >
                                 <ToggleButton
                                    value="header"
                                    aria-label="Header"
                                 >
                                    Header
                                 </ToggleButton>
                                 <ToggleButton
                                    value="italic"
                                    disabled
                                    defaultChecked
                                    aria-label="Italic"
                                 >
                                    Body
                                 </ToggleButton>
                                 <ToggleButton
                                    value="footer"
                                    aria-label="Footer"
                                 >
                                    Footer
                                 </ToggleButton>
                                 <ToggleButton
                                    value="buttons"
                                    aria-label="Buttons"
                                 >
                                    Buttons
                                 </ToggleButton>
                              </StyledToggleButtonGroup>
                           </Box>
                        )}
                        {formats.find((item) => item === "header") && (
                           <Box
                              mt={3}
                              display="flex"
                              alignItems="center"
                              gap={3}
                           >
                              <Typography fontWeight="600" fontSize="18px">
                                 Header
                              </Typography>
                              <TextField
                                 select
                                 size="small"
                                 sx={{ width: "15ch" }}
                                 value={header}
                                 onChange={handleChangeHeader}
                              >
                                 <MenuItem value="image">Image</MenuItem>
                                 <MenuItem value="document">Document</MenuItem>
                                 <MenuItem value="text">Text</MenuItem>
                              </TextField>
                           </Box>
                        )}
                        {selectedCategory !== "authentication" ? (
                           <Box mt={3}>
                              {formats.find((item) => item === "header") &&
                                 header === "text" && (
                                    <>
                                       <TextField
                                          id="outlined-multiline-static"
                                          label="Template Header"
                                          error={
                                             validationError.header &&
                                             !headerText
                                          }
                                          fullWidth
                                          multiline
                                          rows={4}
                                          value={headerText}
                                          onChange={handleHederContent}
                                          helperText={validationError.header}
                                       />

                                       <br />
                                       <span>
                                          {contentLength.header}/60 Charecters
                                       </span>
                                    </>
                                 )}
                              {formats.find((item) => item === "header") &&
                                 (header === "image" ||
                                    header === "document" ||
                                    header === "video") && (
                                    <Box height={100} border="1px solid grey">
                                       <Input
                                          id="outlined-image-upload"
                                          type="file"
                                          accept={
                                             header === "image" ||
                                             header === "video"
                                                ? "image/*"
                                                : ".pdf,.docx,.txt"
                                          }
                                          label="Template Header (Image)"
                                          fullWidth
                                          onChange={handleImageUpload} // Update function to handle image
                                       />
                                    </Box>
                                 )}

                              <>
                                 <Typography
                                    mt={3}
                                    fontWeight="600"
                                    fontSize="18px"
                                 >
                                    Body
                                 </Typography>
                                 <Box
                                    width="100%"
                                    border="1px solid grey"
                                    display="flex"
                                    alignItems="center"
                                 >
                                    <ToggleButtonGroup
                                       onChange={handleBodyTextStyle}
                                       aria-label="text formatting"
                                    >
                                       <ToggleButton
                                          value="bold"
                                          aria-label="bold"
                                       >
                                          B
                                       </ToggleButton>
                                       <ToggleButton
                                          value="italic"
                                          aria-label="Italic"
                                       >
                                          <FontAwesomeIcon icon="fa-solid fa-italic" />
                                       </ToggleButton>
                                       <ToggleButton
                                          value="strike"
                                          aria-label="strike"
                                       >
                                          <FontAwesomeIcon icon="fa-solid fa-strikethrough" />
                                       </ToggleButton>
                                       <ToggleButton
                                          value="code"
                                          aria-label="code"
                                       >
                                          <CodeIcon />
                                       </ToggleButton>
                                    </ToggleButtonGroup>
                                    <EmojiBox onClick={handleOpenDialogue}>
                                       <SentimentSatisfiedAltIcon
                                          sx={{ color: "#fff" }}
                                       />
                                    </EmojiBox>
                                    <Button
                                       variant="outlined"
                                       onClick={addVariable}
                                       sx={{
                                          ml: "5px",
                                          color: "grey",
                                          height: "40px",
                                          borderColor: "grey",
                                       }}
                                    >
                                       Add Variable
                                    </Button>
                                 </Box>
                                 <TextField
                                    sx={{ mt: "20px" }}
                                    id="outlined-multiline-static"
                                    label="Template Body"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={bodyText}
                                    onChange={handleBodyChange}
                                    onMouseUp={handleSelection}
                                    error={validationError.body && !bodyText}
                                    helperText={validationError.body}
                                 />
                                 <span>
                                    {contentLength.body}/1000 Charecters
                                 </span>
                              </>

                              {formats.find((item) => item === "footer") && (
                                 <>
                                    <Typography
                                       mt={3}
                                       fontWeight="600"
                                       fontSize="18px"
                                    >
                                       Footer
                                    </Typography>
                                    <TextField
                                       sx={{ mt: "20px" }}
                                       id="outlined-multiline-static"
                                       label="Template Footer"
                                       fullWidth
                                       multiline
                                       rows={4}
                                       value={footerContent}
                                       onChange={handleFooterChange}
                                       error={
                                          validationError.footer &&
                                          !footerContent
                                       }
                                       helperText={validationError.footer}
                                    />
                                    <span>
                                       {contentLength.footer}/60 Charecters
                                    </span>
                                 </>
                              )}
                           </Box>
                        ) : (
                           <Box>
                              <Typography
                                 mt={3}
                                 fontWeight="600"
                                 fontSize="18px"
                              >
                                 Body
                              </Typography>
                              <TextField
                                 sx={{ mt: "20px" }}
                                 id="outlined-multiline-static"
                                 label="Template Body"
                                 fullWidth
                                 multiline
                                 rows={4}
                                 value={bodyText}
                                 onChange={handleBodyChange}
                                 onMouseUp={handleSelection}
                              />
                              <>
                                 <Box
                                    mt={3}
                                    display="flex"
                                    alignItems="center"
                                    gap={3}
                                 >
                                    <Typography
                                       fontWeight="600"
                                       fontSize="18px"
                                    >
                                       Buttons
                                    </Typography>
                                    <Button
                                       variant="outlined"
                                       sx={{
                                          color: "black",
                                          borderColor: "black",
                                       }}
                                       onClick={handleAddButton}
                                       startIcon={
                                          <FontAwesomeIcon
                                             style={{ color: "black" }}
                                             icon="fa-regular fa-square-plus"
                                          />
                                       }
                                    >
                                       Add Button
                                    </Button>
                                    {/* <TextField select size="small" sx={{ width: "15ch" }}>
                  <MenuItem>Quick Reply</MenuItem>
                  <MenuItem>Call to action</MenuItem>
                </TextField> */}
                                 </Box>
                                 <Box
                                    mt={2}
                                    sx={{
                                       height: "130px",
                                       border: "1px solid grey",
                                       borderRadius: "3px",
                                       overflowY: "scroll",
                                    }}
                                 >
                                    {buttonCount?.map((item, index) => (
                                       <Box
                                          m={2}
                                          key={index}
                                          border="1px solid grey"
                                          width="500px"
                                       >
                                          <Box
                                             display="flex"
                                             alignItems="center"
                                             borderBottom="1px solid grey"
                                             padding="2px 10px"
                                          >
                                             <Typography
                                                sx={{ marginLeft: "10px" }}
                                             >
                                                Button
                                             </Typography>

                                             <select
                                                name="cars"
                                                id={item.id}
                                                style={{
                                                   borderRadius: "5px",
                                                   height: "30px",
                                                   marginLeft: "30px",
                                                }}
                                                onChange={
                                                   handleChangeButtonType
                                                }
                                             >
                                                <option value="QUICK_REPLY">
                                                   Quick Reply
                                                </option>
                                                <option value="PHONE_NUMBER">
                                                   Phone Number
                                                </option>

                                                <option value="URL">Url</option>
                                                <option value="COPY_CODE">
                                                   Code Copy
                                                </option>
                                             </select>
                                          </Box>
                                          {item.type === "URL" ||
                                          item.type === "PHONE_NUMBER" ? (
                                             <Box height="110px">
                                                <Box height="55px">
                                                   <input
                                                      type="text"
                                                      placeholder="Enter the button label name"
                                                      className="btnInput"
                                                   />
                                                </Box>
                                                <Box height="55px">
                                                   <input
                                                      type="text"
                                                      placeholder={
                                                         item.type ===
                                                         "PHONE_NUMBER"
                                                            ? "Enter the phone number"
                                                            : "URL"
                                                      }
                                                      className="btnInput"
                                                   />
                                                   {/* <TextField
                            value=""
                            fullWidth
                            sx={{
                              height: "75px",
                              "& .MuiInputBase-root": {
                                borderRadius: "0px",
                              },
                            }}
                          /> */}
                                                </Box>
                                             </Box>
                                          ) : (
                                             <Box>
                                                <TextField
                                                   value=""
                                                   fullWidth
                                                   sx={{
                                                      "& .MuiInputBase-root": {
                                                         borderRadius: "0px",
                                                      },
                                                   }}
                                                />
                                             </Box>
                                          )}
                                       </Box>
                                    ))}
                                 </Box>
                              </>
                           </Box>
                        )}
                        {formats.find((item) => item === "buttons") && (
                           <>
                              <Box
                                 mt={3}
                                 display="flex"
                                 alignItems="center"
                                 gap={3}
                              >
                                 <Typography fontWeight="600" fontSize="18px">
                                    Buttons
                                 </Typography>
                                 <Button
                                    variant="outlined"
                                    sx={{
                                       color: "black",
                                       borderColor: "black",
                                    }}
                                    onClick={handleAddButton}
                                    startIcon={
                                       <FontAwesomeIcon
                                          style={{ color: "black" }}
                                          icon="fa-regular fa-square-plus"
                                       />
                                    }
                                 >
                                    Add Button
                                 </Button>
                                 {/* <TextField select size="small" sx={{ width: "15ch" }}>
                  <MenuItem>Quick Reply</MenuItem>
                  <MenuItem>Call to action</MenuItem>
                </TextField> */}
                              </Box>
                              <Box
                                 mt={2}
                                 sx={{
                                    height: "130px",
                                    border: "1px solid grey",
                                    borderRadius: "3px",
                                    overflowY: "scroll",
                                 }}
                              >
                                 {buttonCount?.map((item, index) => (
                                    <Box
                                       m={2}
                                       key={index}
                                       border="2px solid black"
                                       width="500px"
                                       borderRadius="5px"
                                    >
                                       <Box
                                          display="flex"
                                          alignItems="center"
                                          borderBottom="2px solid black"
                                          padding="2px 10px"
                                       >
                                          <Typography
                                             sx={{ marginLeft: "10px" }}
                                          >
                                             Button
                                          </Typography>

                                          <select
                                             name="cars"
                                             id={item.id}
                                             style={{
                                                borderRadius: "5px",
                                                height: "30px",
                                                marginLeft: "30px",
                                             }}
                                             onChange={handleChangeButtonType}
                                          >
                                             <option value="QUICK_REPLY">
                                                Quick Reply
                                             </option>
                                             <option value="PHONE_NUMBER">
                                                Phone Number
                                             </option>

                                             <option value="URL">Url</option>
                                             <option value="COPY_CODE">
                                                Code Copy
                                             </option>
                                          </select>
                                          <button
                                             style={{ flex: "end" }}
                                             onClick={() =>
                                                handleRemoveButton(item.id)
                                             }
                                          >
                                             Close
                                          </button>
                                       </Box>
                                       {item.type === "URL" ||
                                       item.type === "PHONE_NUMBER" ? (
                                          <Box height="116px">
                                             <Box height="55px">
                                                <input
                                                   type="text"
                                                   placeholder="Enter the button label name"
                                                   className="btnInput"
                                                   value={item.text}
                                                   onChange={(e) =>
                                                      handleLableChange(
                                                         e,
                                                         item.id
                                                      )
                                                   }
                                                />
                                             </Box>
                                             <Box height="55px">
                                                <input
                                                   type="text"
                                                   placeholder={
                                                      item.type ===
                                                      "PHONE_NUMBER"
                                                         ? "Enter the phone number"
                                                         : "URL"
                                                   }
                                                   className="btnInput"
                                                   value={
                                                      item.type ===
                                                      "PHONE_NUMBER"
                                                         ? item.phone_number
                                                         : item.url
                                                   }
                                                   onChange={(e) =>
                                                      handleButtonValue(
                                                         e,
                                                         item.id
                                                      )
                                                   }
                                                />
                                             </Box>
                                          </Box>
                                       ) : (
                                          <Box>
                                             <TextField
                                                fullWidth
                                                value={
                                                   item.type === "QUICK_REPLY"
                                                      ? item.quick_replay
                                                      : item.copy_code
                                                }
                                                onChange={(e) =>
                                                   handleLableChange(e, item.id)
                                                }
                                                sx={{
                                                   "& .MuiInputBase-root": {
                                                      borderRadius: "0px",
                                                   },
                                                }}
                                             />
                                          </Box>
                                       )}
                                    </Box>
                                 ))}
                              </Box>
                           </>
                        )}

                        <Box gap={2} mt={4}>
                           <CustomBtn variant="contained">
                              Discard Changes
                           </CustomBtn>
                           <CustomBtn
                              type="submit"
                              sx={{ ml: "20px" }}
                              variant="contained"
                           >
                              Preview and Submit
                           </CustomBtn>
                        </Box>
                     </Grid>
                     {/* <Grid item md={4}>
                        <PreviewBox />
                     </Grid> */}
                  </Grid>
               </MainBox>
            </form>
            <EmojiDialog
               open={openEmoji}
               closeDialogue={handleCloseDialogue}
               setSelectedEmoji={setBodyText}
            />
            <TemplatePreviewModal
               open={preview}
               bodyText={bodyText}
               handleClose={closePreviewModel}
               setExamples={setExamples}
               examples={examples}
               handleCreateTemplate={handleCreateTemplate}
               setIsVariableExist={setIsVariableExist}
            />
         </OuterBox>
      </>
   );
}

export default MainContainer;
