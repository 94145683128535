import { Avatar, Box, Typography, styled, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import axios from "axios";
import moment from "moment";
import pdfIcon from "../../../assets/images/pdf.png";
import excelIcon from "../../../assets/images/excel-logo.png";
import wordIcon from "../../../assets/images/word.jpg";
import {
   parseText,
   sendMessageStatus,
} from "../../../constant/helperFunctions";

const Message = styled(Box)({
   display: "flex",
   flexDirection: "column",
});

function TemplateMessage({ content,templates }) {
   const [footerMessage, setFooterMessage] = useState("");
   const [bodyMessage, setBodyMessage] = useState("");
   const [headerMessage, setHeaderMessage] = useState({});
   const [buttonText, setButtonText] = useState("");
   // const [templates, setTemplates] = useState("");
   const [buttons, setButtons] = useState([]);


   const parcedContent = JSON.parse(content?.message_input);
   const templateData = content.message?.template;
   const variables = parcedContent?.body
      ? JSON.parse(parcedContent?.body)
      : null;
   const statusIcon = sendMessageStatus(content?.status);

   const time = moment(content.createdAt).format("h:mm a");

  
   // Function to replace variable in the body text

   const replaceVariables = (text, variables) => {
      let replacedText = text;
      variables.forEach(({ id, text }) => {
         replacedText = replacedText.replace(id, text);
      });
      return replacedText;
   };

   useEffect(() => {
      if (templates?.length > 0) {
         console.log(parcedContent)
         const { templateName } = JSON.parse(parcedContent?.template);
         const selectedTemplateContent = templates.find(
            (temp) => temp.name === templateName
         );
         console.log({ selectedTemplateContent });

         if (selectedTemplateContent) {
            const { components } = selectedTemplateContent;

            for (const component of components) {
               switch (component.type) {
                  case "HEADER":
                     console.log({component})
                     const { format } = component;

                     let headerContent = {
                        type: format,
                     };
                     if (format === "TEXT") {
                        headerContent.text = component.text;
                     }
                     // const headerComponent = JSON.parse(parcedContent?.header);
                     const extension = templateData?.mediaUrl
                        ?.split(".")
                        .pop()
                        .split(/\#|\?/)[0];

                     headerContent.url = templateData?.mediaUrl;
                     headerContent.extension = extension;

                     setHeaderMessage(headerContent);
                     break;
                  case "BODY":
                     const parcedBodyText = JSON.stringify(component.text);
                     let result;
                     if (variables) {
                        result = replaceVariables(parcedBodyText, variables);
                        setBodyMessage(result.slice(1, -1));

                        // setBodyMessage(result.slice(1, -1));
                     } else {
                        // setBodyMessage(parcedBodyText.slice(1, -1));
                        const afterTrim = parcedBodyText.slice(1, -1);
                        setBodyMessage(afterTrim.trim());
                     }
                     break;
                  case "FOOTER":
                     setFooterMessage(component.text);
                     break;
                  case "BUTTONS":
                     setButtons(component.buttons);
                     break;
                  default:
                     // Handle unknown component type
                     break;
               }
            }
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [templates]);

   return (
      <Box
         mt={1}
         //   key={content._id}
         sx={{
            height: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
         }}
      >
         {/* {content.flag === "incoming" && (
      <Box p={1}>
        <Avatar
          sx={{
            width: "30px",
            height: "30px",
            backgroundColor: "black",
            color: "#fff",
          }}
        />
      </Box>
      )} */}
         <Message>
            {/* <Typography sx={{ fontSize: "11px", fontWeight: "600" }}>
          John Doe
        </Typography> */}
            <Box
               p={1}
               sx={{
                  width: "auto",
                  height: "auto",
                  backgroundColor: "#d9fdd3",
                  maxWidth: "300px",
                  //   content.flag === "incoming" ? "#F4F4F6" : "#2A8BC7",
                  borderRadius: "5px 5px 5px 5px",
                  //   content.flag === "incoming"
                  //     ? "0px 15px 15px 15px"
                  //     : "15px 15px 0px 15px",
               }}
            >
               {headerMessage?.type === "IMAGE" && (
                  <img
                     src={headerMessage?.url}
                     alt="whatsappimage"
                     style={{
                        objectFit: "contain",
                        height: "100%",
                        width: "100%",
                     }}
                  />
               )}
               {headerMessage?.type === "VIDEO" && (
                  <video
                     style={{
                        objectFit: "contain",
                        height: "100%",
                        width: "100%",
                     }}
                     controls
                  >
                     <source src={headerMessage?.url} type="video/mp4" />
                  </video>
               )}
               {headerMessage?.type === "DOCUMENT" && (
                  <a
                     href={headerMessage?.url}
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <div
                        style={{
                           height: "40px",
                           width: "30px",
                           display: "flex",
                        }}
                     >
                        {headerMessage?.extension === "pdf" && (
                           <img
                              src={pdfIcon}
                              alt="document"
                              width="100%"
                              height="30px"
                           />
                        )}
                        {headerMessage?.extension === "xlsx" && (
                           <img
                              src={excelIcon}
                              alt="document"
                              width="100%"
                              height="30px"
                           />
                        )}
                        {headerMessage?.extension === "doc" && (
                           <img
                              src={excelIcon}
                              alt="document"
                              width="100%"
                              height="30px"
                           />
                        )}
                     </div>
                     {/* <div>
                <p style={{ color: "grey", fontSize: "11px" }}>{content?.message?.image?.fileName}</p>
              </div> */}
                  </a>
               )}
               {headerMessage?.type==="TEXT"&&(
                <Typography fontSize="12px" fontWeight="500">{headerMessage?.text}</Typography>
               )}
               <span style={{ whiteSpace: "pre-line", fontSize: "13px" }}>
                  {parseText(bodyMessage)}
               </span>

               {footerMessage && (
                  <Typography
                     variant="body1"
                     sx={{ fontSize: "11px", color: "grey" }}
                     color="initial"
                  >
                     {footerMessage}
                  </Typography>
               )}
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "end",
                     alignItems: "center",
                  }}
               >
                  <Typography
                     sx={{
                        fontSize: "11px",
                        fontWeight: "400",
                        color: "#978f8f",
                        textAlign:
                           content.flag === "incoming" ? "start" : "end",
                     }}
                  >
                     {time}
                  </Typography>
                  {content.flag === "outgoing" && statusIcon}
               </Box>
            </Box>
            {buttons.map((btn) => (
               <Box mt={1 / 2} display="flex" justifyContent="space-between">
                  {/* <Box bgcolor="#fff" borderRadius="5px" mr={1} width="100%" textAlign="center">
              <Button variant="text" color="primary" sx={{ textTransform: "capitalize" }}>
                {time}
              </Button>
            </Box> */}
                  <Box
                     bgcolor="#fff"
                     borderRadius="5px"
                     width="100%"
                     textAlign="center"
                  >
                     <Button
                        variant="text"
                        color="primary"
                        sx={{ textTransform: "capitalize" }}
                     >
                        {btn.text}
                     </Button>
                  </Box>
               </Box>
            ))}

            {/* {buttonText && (
          <Box mt={1 / 2} display="flex" justifyContent="space-between">
            <Box bgcolor="#fff" borderRadius="5px" width="100%" textAlign="center">
              <Button variant="text" color="primary" sx={{ textTransform: "capitalize" }}>
                <OpenInNewRoundedIcon sx={{ fontSize: "15px", marginRight: "5px" }} /> click here
              </Button>
            </Box>
          </Box>
        )} */}
         </Message>
         <Box p={1}>
            <Avatar
               sx={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: "black",
                  color: "#fff",
               }}
            />
         </Box>
      </Box>
   );
}

export default TemplateMessage;
